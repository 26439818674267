import React, { useEffect, useState } from "react";
import firebase from "firebase/app"
import "firebase/auth"
import UserContext from "./userContext";

var uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: './',
  signInOptions: [
    // List of OAuth providers supported.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
}

var firebaseConfig = {
  apiKey: "AIzaSyD7v9GFa71VkeBn_gjyQTMLt_hxp5LupMg",
  authDomain: "sortedcoder-cff69.firebaseapp.com",
  databaseURL: "https://sortedcoder-cff69-default-rtdb.firebaseio.com",
  projectId: "sortedcoder-cff69",
  storageBucket: "sortedcoder-cff69.appspot.com",
  messagingSenderId: "355194125141",
  appId: "1:355194125141:web:3a453bdc7c0975efd20ddc",
  measurementId: "G-33VRKE6SVN"
}

const FirebaseContext = React.createContext({
  initialized: false,
  firebase: {auth : function() {}},
  uiConfig: uiConfig,
});

// We use this context provider to wrap all components within it
// Thus inside every component we can access a fully initialized firebase like this -> var fb = useContext(FirebaseContext)
// in gatsby-browser.js
// so code looks like this -
// <FirebaseContextProvider>
//     <everyPageComponent/>
// </FirebaseContextProvider>
const FirebaseAndUserContextProvider = ({children}) => {

    const [_firebase, set_firebase] = useState({})
    const [user, setUser] = useState({isSignedIn:false, user: null})

    // We must do it in the useEffect since 
   ///   - firebase depens on windows internally
    //   - window is not available during gatsby build which does SSR (server side rendering)
    //   - useEffect does not get executed during gatsby build
    useEffect(()=>{
      // Intialize and store firebase variable, when this happens it should trigger a re-render of dependent components
      firebase.initializeApp(firebaseConfig);
      set_firebase({
        initialized: true,
        firebase: firebase,
        uiConfig: uiConfig,
      });

      firebase.auth().onAuthStateChanged((user) => {
        
        if (user) { // User signs in successfully
          setUser({isSignedIn: true, user: user });
        } else {    // User is signed out
          setUser({isSignedIn: false, user: null });
        }
      });
      
    },[]) // TODO: document what happens by passing empty [] here, https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects

    return (
      <FirebaseContext.Provider value={_firebase}>
        <UserContext.Provider value={user}>
         {children}
        </UserContext.Provider>
      </FirebaseContext.Provider>
    )
  }

export default FirebaseContext
export {FirebaseAndUserContextProvider}

