/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { FirebaseAndUserContextProvider} from "./src/components/context/firebaseContext";
// React is imported because of error " 'React' must be in scope when using JSX "
import React from "react"

import "./src/styles/global.css";
require("./src/styles/codestyle.css");


// We are injecting the FirebaseContext into every page
export const wrapRootElement = ({element}) => (
    <FirebaseAndUserContextProvider>{element}</FirebaseAndUserContextProvider>
)

